<template>
  <div
    class="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500"
  >
    <div class="text-center">
      <h1 class="h3">Forgot your password?</h1>
      <p class="mb-4">
        Just type in your email and we will send you an instruction to reset
        your password!
      </p>
    </div>
    <form @submit.prevent="ProceedToReset">
      <!-- Form -->
      <div class="mb-4">
        <label for="email">Your Email</label>
        <InputWrapper :errors="formError.email">
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="formData.email"
            required
            autofocus
          />
        </InputWrapper>
      </div>
      <!-- End of Form -->
      <button type="submit" class="btn btn-block btn-primary">
        Recover password
      </button>
    </form>
    <div class="d-flex justify-content-center align-items-center mt-4">
      <span class="font-weight-normal">
        Go back to the
        <router-link to="login" class="font-weight-bold">
          login page
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import InputWrapper from "@/components/InputWrapper";
import { ref } from "vue";
import { apiPost } from "@/api";
import { useRouter } from "vue-router";
import { Toast } from "@/utils/notify";
export default {
  name: "ForgotPassword",
  components: { InputWrapper },
  setup() {
    const loading = ref(false);
    const formData = ref({
      email: null
    });
    const formError = ref({});

    const router = useRouter();

    const ProceedToReset = async () => {
      loading.value = true;

      try {
        const response = await apiPost("user/auth/forgot-password");
        formData.value.ref = response.data.dt.ref;
        router.replace("/auth/login").then();
        Toast.fire({
          icon: "confirmation",
          title: response.data.msg,
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: e.message,
        }).then();
      }

      loading.value = false;
    };

    return {
      loading,
      formData,
      formError,
      ProceedToReset
    };
  }
};
</script>

<style scoped></style>
